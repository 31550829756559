import {gql, useMutation} from '@apollo/client'
//import { graphql } from "gatsby"

export const SDG_FIELDS_FRAGMENT = gql`
    fragment SdgFields on Sdg {
        id
        databaseId
        parentDatabaseId
        SdgMeta {
            points
        }
    }
`



export const TEAM_FIELDS_FRAGMENT = gql`
    fragment TeamFieldsFragment on Team {
        id
        slug
        name
        databaseId
        teammeta {
            description
            fieldGroupName
            private
            creator
            timestamp
            visible
          }
    }
`

export const PLAYER_FIELDS_FRAGMENT = gql`
    ${SDG_FIELDS_FRAGMENT}

    fragment PlayerFieldsFragment on Player {
        id
        title
        databaseId
        playerMeta {
            id
            nickName
            wishes {
                ... on Wish {
                    id
                    databaseId
                    wishesmeta {
                        title
                        description
                        sdg
                    }
                }
            }
            finishedTasks {
                ...SdgFields
            }
            tudus {
                ...SdgFields
            }
        }
    }
`

export const PLAYER_FINISHED_TASKS = `
    ... on Sdg {
      id
      title(format: RENDERED)
      parent {
        node {
          ... on Sdg {
            id
            databaseId
            slug
          }
        }
      }
      SdgMeta {
        points
      }
    }
`

export const GET_CODE_MUTATION = gql`
    mutation createPlayerFromNickNameMutation($email: String, $nickName: String, $clientMutationId: String, $recaptchaToken: String) {
        __typename
        createPlayerFromNickName(
            input: {
                clientMutationId: $clientMutationId
                email: $email
                nickName: $nickName
                recaptchaToken: $recaptchaToken
            }
        ) {
            error
            success
            code
        }
    }
`



export const GET_PLAYER_BY_CODE_QUERY = gql`
    ${PLAYER_FIELDS_FRAGMENT}
    ${TEAM_FIELDS_FRAGMENT}
    
    query getPlayerByCodeQuery($code: ID!) {
        player(id: $code, idType: SLUG) {
            ...PlayerFieldsFragment
            teams(first: 1000) {
                nodes {
                    ...TeamFieldsFragment
                }
            }
#            playerMeta { #this is commented out, because another query will get that data after login
#                teamInvites {
#                    ...TeamFieldsFragment
#                }
#            }
        }
    }
`

export const GET_TEAM_QUERY = gql`
    query getTeam($slug: ID!) {
        team(id: $slug, idType: SLUG) {
            name
            teammeta {
                description
                fieldGroupName
                private
                creator
                timestamp
                visible
                creator
            }
            players(first: 500) {
                nodes {
                    playerMeta {
                        id
                        nickName
                        finishedTasks {
                            ... on Sdg {
                                id
                                title(format: RENDERED)
                                parent {
                                    node {
                                        ... on Sdg {
                                            slug
                                            id
                                            databaseId
                                            title
                                        }
                                    }
                                }
                                SdgMeta {
                                    points
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export const GET_MY_TEAM_INVITES_QUERY = gql`
    ${TEAM_FIELDS_FRAGMENT}

    query getMyTeamsInvites($code: ID!) {
        player(id: $code, idType: SLUG) {
            id
            playerMeta {
                invites {
                    inviter {
                        ... on Player {
                            id
                            databaseId
                            playerMeta {
                                nickName
                            }
                        }
                    }
                    team {
                        ...TeamFieldsFragment
                    }
                }
            }
        }
    }
`

export const GET_MY_TEAMS_AND_OTHER_TEAMS_LIGHT_QUERY = gql`
    ${TEAM_FIELDS_FRAGMENT}

    query getMyTeamsLight($code: ID!) {
        player(id: $code, idType: SLUG) {
            id
            teams(first: 1000) {
                nodes {
                    ...TeamFieldsFragment
                }
            }
        }
      teams(first: 1000) {
        nodes {
          name
          slug
          teammeta {
            description
            fieldGroupName
            private
            creator
            timestamp
            visible
          }
        }
      }
    }
`

export const GET_MY_TEAMS_QUERY = gql`

    query getMyTeams($code: ID!) {
        player(id: $code, idType: SLUG) {
            id
            teams {
                nodes {
                    id
                    name
                    slug
                    players {
                        nodes {
                            playerMeta {
                                id
                                nickName
                                finishedTasks {
                                    ${PLAYER_FINISHED_TASKS}
                                }
                            }
                            id
                        }
                    }
                }
            }
        }
    }
`

export const CREATE_TEAM = gql`
    ${TEAM_FIELDS_FRAGMENT}

    mutation createTeamMutation($code: ID!, $newTeamName: String!, $description: String!, $isPrivate: String!, $clientMutationId: String) {
        createTeamMutation(
            input:{
                code: $code,
                newTeamName: $newTeamName,
                description: $description,
                private: $isPrivate,
                clientMutationId: $clientMutationId
            }) {
            player {
                id
                teams {
                    nodes {
                        ...TeamFieldsFragment
                    }
                }
            }
            error
            success
        }
    }
`

export const ADD_PLAYER_TO_TEAM_MUTATION = gql`
    mutation AddPlayerToTeamMutation($code: ID!, $slug: ID!, $friendsNickName: String!, $clientMutationId: String) {
        addPlayerToTeamMutation(
            input:
            {
                code: $code,
                friendsNickName:
                $friendsNickName,
                teamSlug: $slug,
                clientMutationId: $clientMutationId
            }
        ) {
            clientMutationId
            team {
                players {
                    nodes {
                        id
                        playerMeta {
                            nickName
                        }
                    }
                }
                id
            }
            error
            success
        }
    }
`

export const JOIN_TEAM_MUTATION = gql`
    ${TEAM_FIELDS_FRAGMENT}

    mutation JoinTeamMutation($code: ID!, $slug: ID!, $clientMutationId: String) {
        joinTeamMutation(
            input:
            {
                code: $code,
                teamSlug: $slug,
                clientMutationId: $clientMutationId
            }
        ) {
            clientMutationId
            error
            success
            player{
                id
                teams {
                    nodes {
                        ...TeamFieldsFragment
                    }
                }
            }
        }
    }
`

export const INVITE_PLAYER_TO_TEAM_MUTATION = gql`
    mutation InvitePlayerToTeamMutation($code: ID!, $slug: ID!, $friendsNickName: String!, $clientMutationId: String) {
        invitePlayerToTeamMutation(
            input:
            {
                code: $code,
                friendsNickName:
                $friendsNickName,
                teamSlug: $slug,
                clientMutationId: $clientMutationId
            }
        ) {
            clientMutationId
            error
            success
        }
    }
`

export const DECLINE_INVITATION_MUTATION = gql`
    mutation DeclineInvitationMutation($code: ID!, $slug: ID!) {
        declineInvitationMutation(input: { code: $code, teamSlug: $slug }) {
            error
            success
        }
    }
`

export const LEAVE_TEAM_MUTATION = gql`
    ${TEAM_FIELDS_FRAGMENT}

    mutation leaveTeamMutation($code: ID!, $slug: ID!) {
        leaveTeamMutation(input: {code: $code, teamSlug: $slug}) {
            error
            success
            player{
                id
                teams {
                    nodes {
                        ...TeamFieldsFragment
                    }
                }
            }
        }
    }
`

export const UPDATE_PLAYER_MUTATION = gql`
    ${PLAYER_FIELDS_FRAGMENT}

    mutation updatePlayerMutation(
        $code: ID!
        $finishedTaskIds: [Int]
        $tuduIds: [Int]
        $wishesIds: [Int]
        $teamInviteIds: [TeamInviteInput]
        $clientMutationId: String
    ) {
        updatePlayerMutation(
            input: {
                clientMutationId: $clientMutationId
                finishedTaskIds: $finishedTaskIds
                tuduIds: $tuduIds
                wishesIds: $wishesIds
                teamInviteIds: $teamInviteIds
                code: $code
            }
        ) {
            player {
                ...PlayerFieldsFragment
            }
        }
    }
`

export const SEND_WISH_FORM = gql`
    mutation sendFormMutation(
        $userid: ID!
        $title: String!
        $description: String!
        $sdg: String!
    ) {
        sendFormMutation(
            input: {
                userid: $userid
                title: $title
                description: $description
                sdg: $sdg
            }
        ) {
            success
        }
    }
`

export const UPDATE_WISH_MUTATION = gql`
    mutation updateWishMutation(
        $playerid: ID!
        $wishid: ID!
    ) {
        updateWishMutation(
            input: {
                playerid: $playerid
                wishid: $wishid
            }
        ) {
            players
        }
    }
`

export const GET_WISHES  = gql`
    query getWishes {
      wishes(first: 1000) {
          nodes {
            id
            databaseId
            wishesmeta {
              userid
              description
              fieldGroupName
              likes
              sdg
              visible
              title
            }
          }
      }
    }`
