import React, { createContext, useContext, useEffect } from 'react'
import useUser from './useUser'
import { useQuery } from '@apollo/client'
import { GET_MY_TEAM_INVITES_QUERY } from '../graphql/queries'
import _ from 'lodash'
import { Button } from '@material-ui/core'
import { useToasts } from 'react-toast-notifications'
import useLocalStorage from './useLocalStorage'
import useTeam from './useTeam'
import useGameConfig from './useGameConfig'

const TeamInviteNotificationContext = createContext()

export function TeamInviteNotificationProvider ({ children }) {
  const { texts: { notificationTexts } } = useGameConfig()
  const { addToast, removeToast } = useToasts()
  const { user, signedIn, setTeamInvites, removeTeamInvite } = useUser()
  const { join, declineInvitation } = useTeam()
  const [notifications, storeNotifications] = useLocalStorage(process.env.NOTIFICATIONS_DATA_KEY, [])

  const { data, startPolling, stopPolling } = useQuery(GET_MY_TEAM_INVITES_QUERY, {
    variables: { code: user?.code },
    skip: !signedIn,
    ssr: false
  })

  function removeNotificationBySlug (slug) {
    const updatedNotifications = notifications.filter(notification => {
      return notification.groupDetails.slug !== slug
    })

    storeNotifications(updatedNotifications)
  }

  let invites = []
  let newTeamInvites = []
  let differences = []

  useEffect(() => {
    if (signedIn) {
      startPolling(5000)
    } else {
      stopPolling()
    }

    if (data?.player?.playerMeta?.invites) {
      invites = user.teamInviteIds ? Array.from(user.teamInviteIds, team => team.team[0].name) : []
      newTeamInvites = Array.from(data.player.playerMeta.invites, team => team?.team[0].name)

      differences = _.difference(newTeamInvites, invites)

      if (differences.length) {
        let updatedNotifications = notifications
        differences.forEach(difference => {
          let id = ''
          const team = data.player.playerMeta.invites.find(team => team.team[0].name === difference)
          updatedNotifications = [{
            action: 'added-to-group',
            groupDetails: { name: difference, slug: team.team[0].slug, inviter: team.inviter?.playerMeta.nickName }
          }, ...updatedNotifications]

          const getCurrentToastId = () => id

          addToast(
            <div>

              <p dangerouslySetInnerHTML={{
                __html: notificationTexts.youAreInvitedToTheGroup
                  .replace('${inviter}', `<strong>${team.inviter.playerMeta.nickName}</strong>`)
                  .replace('${groupName}', `<strong>${difference}</strong>`)
              }}/>

              <div className="invite-buttons">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    removeTeamInvite(team.team[0])
                    join(team.team[0])
                    removeNotificationBySlug(team.team[0].slug)
                    removeToast(getCurrentToastId())
                  }}
                >
                  Beitreten
                </Button>
                <Button variant="contained" onClick={() => {
                  declineInvitation(team.team[0]).then(r => removeNotificationBySlug(team.team[0].slug))
                  removeToast(getCurrentToastId())
                }}
                >
                  Ablehnen
                </Button>
              </div>
            </div>,
            { appearance: 'info', autoDismiss: true, autoDismissTimeout: 10000 }, toastId => { id = toastId })
        })
        storeNotifications(updatedNotifications)
        setTeamInvites(data.player.playerMeta.invites)
      }
    }
  }, [data])

  return (
    <TeamInviteNotificationContext.Provider value={{ notifications, removeNotificationBySlug, storeNotifications }}>
      {children}
    </TeamInviteNotificationContext.Provider>
  )
}

const useTeamInviteNotifier = () => useContext(TeamInviteNotificationContext)

export default useTeamInviteNotifier