module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://p412496.webspaceconfig.de/webapps/wirkel/graphql","type":{"Page":{"exclude":false},"Post":{"exclude":true},"Menu":{"exclude":true},"MenuItem":{"exclude":true},"Player":{"exclude":true},"User":{"exclude":true},"UserRole":{"exclude":true},"Tag":{"exclude":true},"Category":{"exclude":true},"Comment":{"exclude":true},"PostFormat":{"exclude":true},"ContentType":{"exclude":true},"Team":{"exclude":true},"MediaItems":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://p412496.webspaceconfig.de/webapps/wirkel/graphql"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Der Wirkel","short_name":"Wirkel","start_url":"/","background_color":"#fff","theme_color":"#26BDE2","display":"standalone","icon":"static/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ecc6e30cc9342bd3322a168369510493"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*","/app/overview"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
