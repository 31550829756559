import React from 'react'
import { useMutation } from '@apollo/client'
import {
  CREATE_TEAM,
  INVITE_PLAYER_TO_TEAM_MUTATION, JOIN_TEAM_MUTATION,
  LEAVE_TEAM_MUTATION
} from '../graphql/queries'
import { v4 as uuidv4 } from 'uuid'
import useUser from './useUser'
import useSingleToast from './useSingleToast'
import useGameConfig from './useGameConfig'

function useTeam () {
  const { addSingleToast } = useSingleToast()
  const { user, setTeams, removeTeamInvite } = useUser()
  const [invitePlayerToGroup] = useMutation(INVITE_PLAYER_TO_TEAM_MUTATION)
  const [addTeam] = useMutation(CREATE_TEAM)
  const [leaveTeam] = useMutation(LEAVE_TEAM_MUTATION)
  const [joinTeam] = useMutation(JOIN_TEAM_MUTATION)
  const { texts: { notificationTexts } } = useGameConfig()

  function add (newTeamName, description, isPrivate, setErrorMessage) {
    return new Promise((resolve, reject) => {
      addTeam({
        variables: {
          clientMutationId: uuidv4(),
          code: user.code,
          newTeamName: newTeamName,
          description: description,
          isPrivate: isPrivate
        },
        options: {
          onError: e => {
            setErrorMessage(e.message)
            reject({ error: true, message: e.message, data: null })
          },
        },
      }).then(r => {
        if (r.data.createTeamMutation.success) {
          setTeams(r.data.createTeamMutation.player.teams.nodes)
          addSingleToast(
            <div>
              <p>{notificationTexts.groupAdded}</p>
            </div>,
            { appearance: 'success' })
          setErrorMessage(null)
          resolve({ error: false, message: false, data: r.data })
        } else {
          addSingleToast(
            <div>
              <p>{r.data.createTeamMutation.error}</p>
            </div>,
            { appearance: 'error' })
          setErrorMessage(r.data.createTeamMutation.error)
          reject({ error: true, message: r.data.createTeamMutation.error, data: null })
        }

      })
    })
  }

  function invite (playersNickName, slug, setErrorMessage) {
    return new Promise((resolve, reject) => {

      invitePlayerToGroup({
        variables: {
          clientMutationId: uuidv4(),
          code: user.code,
          slug: slug,
          friendsNickName: playersNickName,
        },
        options: {
          onError: e => {
            setErrorMessage(e.message)
            reject({ error: true, message: e.message, data: null })
          },
        },
      }).then(r => {
        if (r.data.invitePlayerToTeamMutation.success === false) {
          addSingleToast(
            <div>
              <p>{r.data.invitePlayerToTeamMutation.error}</p>
            </div>,
            { appearance: 'error' })
          setErrorMessage(r.data.invitePlayerToTeamMutation.error)
          reject({ error: true, message: r.data.invitePlayerToTeamMutation.error, data: null })
          return null
        }

        addSingleToast(
          <div>
            <p>{notificationTexts.friendInvited}</p>
          </div>,
          { appearance: 'success' })
        setErrorMessage(null)
        resolve({ error: false, message: false, data: r.data })
        return null
      })

    })
  }

  function declineInvitation (team) {
    return new Promise((resolve, reject) => {
      removeTeamInvite(team)
      addSingleToast(
        <div>
          <p>{notificationTexts.inviteDeclined}</p>
        </div>,
        { appearance: 'success' }
      )
      resolve()
    })
  }

  function join (team, setErrorMessage = () => {}) {
    return new Promise((resolve, reject) => {

      joinTeam({
        variables: {
          clientMutationId: uuidv4(),
          code: user.code,
          slug: team.slug
        },
        options: {
          onError: e => {
            setErrorMessage(e.message)
            reject({ error: true, message: e.message, data: null })
          },
        },
      }).then(r => {
        if (r.data.joinTeamMutation.success === false) {
          addSingleToast(
            <div>
              <p>{r.data.joinTeamMutation.error}</p>
            </div>,
            { appearance: 'error' })
          setErrorMessage(r.data.joinTeamMutation.error)
          reject({ error: true, message: r.data.joinTeamMutation.error, data: null })
          return null
        }

        addSingleToast(
          <div>
            <p>{notificationTexts.youJoinedGroup}</p>
          </div>,
          { appearance: 'success' })

        setErrorMessage(null)

        removeTeamInvite(team).then(updatedUser => {
          setTeams(r.data.joinTeamMutation.player.teams.nodes, updatedUser)
        })

        resolve({ error: false, message: false, data: r.data })
        return null
      })

    })
  }

  function leave (slug) {
    return new Promise((resolve, reject) => {

      leaveTeam({
        variables: {
          code: user.code,
          slug: slug
        }
      }).then(r => {
        setTeams(r.data.leaveTeamMutation.player.teams.nodes)
        addSingleToast(
          <div>
            <p>{notificationTexts.youLeftGroup}</p>
          </div>,
          { appearance: 'success' }
        )
        resolve({ error: false, message: false, data: r.data })
      }).catch(e => {
        reject({ error: true, message: e.message, data: null })
      })
    })
  }

  return { add, invite, join, leave, declineInvitation }
}

export default useTeam