import React, { createContext, useContext, useEffect, useState } from "react"
import useLocalStorage from "./useLocalStorage"
import { graphql, useStaticQuery } from "gatsby"
import produce from "immer"
import { client } from "../services/apollo"
import { useMutation } from "@apollo/client"
import { UPDATE_PLAYER_MUTATION } from "../graphql/queries"
import { v4 as uuidv4 } from "uuid"
import useNetwork from "./useNetwork"

const ALL_MISSION_POINTS_QUERY = graphql`
  query allMissionPoints {
    allWpSdg(
      sort: { fields: menuOrder, order: ASC }
      filter: { template: { templateName: { eq: "Missions" } } }
    ) {
      nodes {
        SdgMeta {
          points
        }
        databaseId
      }
    }
  }
`

export const UserContext = createContext(null)

export function UserProvider({ children }) {
  const [storedUser, storeUser] = useLocalStorage(
    process.env.AUTH_DATA_KEY,
    undefined
  )
  const [syncStatus, setSyncStatus] = useState(storeUser.syncStatus)
  const [signedIn, setSignedIn] = useState(!!storedUser?.id)
  const {
    allWpSdg: { nodes },
  } = useStaticQuery(ALL_MISSION_POINTS_QUERY)
  const [user, setUser] = useState(storedUser)
  const [points, setPoints] = useState(storedUser?.points ?? 0)
  const isOnline = useNetwork()
  const [notifications, storeNotifications] = useLocalStorage(
    process.env.NOTIFICATIONS_DATA_KEY,
    []
  )

  const normalizeAuthData = (authData, syncStatus = "notSynced") => {
    return {
      id: authData?.player?.databaseId,
      code: authData?.player?.title,
      nickName: authData?.player?.playerMeta.nickName,
      points: authData?.player?.playerMeta.points ?? 0,
      tudus: authData?.player?.playerMeta?.tudus ?? [],
      wishes: authData?.player?.playerMeta?.wishes ?? [],
      finishedTasks: authData?.player?.playerMeta?.finishedTasks ?? [],
      teams: authData?.player?.teams?.nodes ?? [],
      teamInviteIds: authData?.player?.playerMeta?.invites ?? [],
      syncStatus: syncStatus,
    }
  }

  const cleanUpMission = originalMission => {
    return produce(originalMission, draft => {
      delete draft.SdgMetaAfterContent
      delete draft.content
      delete draft.differencesFor
    })
  }

  const [updatePlayerMutation, { loading }] = useMutation(
    UPDATE_PLAYER_MUTATION
  )

  if (loading) {
    //setSyncStatus('syncing') //TODO: this is causing infinite loop
  }

  const setRawUser = (rawUser, syncStatus) => {
    const newUser = normalizeAuthData(rawUser, syncStatus)
    newUser.points = userPointCalculator(newUser, nodes)
    setPoints(newUser.points)
    newUser.syncStatus = syncStatus
    setSyncStatus(newUser.syncStatus)
    setSignedIn(true)

    storeUser(newUser)
    setUser(newUser)
  }

  const removeDoneMission = mission => {
    const newUser = produce(user, draft => {
      draft.finishedTasks = user.finishedTasks.filter(
        finishedTask => finishedTask.databaseId !== mission.databaseId
      )
      draft.points = userPointCalculator(draft, nodes)
      setPoints(draft.points)
      draft.syncStatus = "notSynced"
      setSyncStatus(draft.syncStatus)
    })

    storeUser(newUser)
    setUser(newUser)
  }

  const addDoneMission = mission => {
    return new Promise(async (resolve, reject) => {
      const newUser = produce(user, draft => {
        mission = cleanUpMission(mission)
        draft.finishedTasks = [mission, ...user.finishedTasks]
        draft.points = userPointCalculator(draft, nodes)
        setPoints(draft.points)
        draft.syncStatus = "notSynced"
        setSyncStatus(draft.syncStatus)

        return draft
      })

      await storeUser(newUser)
      await setUser(newUser)
      resolve(newUser)
    })
  }

  const addFavorite = mission => {
    const newUser = produce(user, draft => {
      mission = cleanUpMission(mission)

      draft.tudus = [mission, ...user.tudus]
      draft.syncStatus = "notSynced"
      setSyncStatus(draft.syncStatus)

      return draft
    })

    storeUser(newUser)
    setUser(newUser)
  }

  const removeFavorite = (mission, updatedUser = null) => {
    const newUser = produce(updatedUser ?? user, draft => {
      draft.tudus = user.tudus.filter(
        favorite => favorite.databaseId !== mission.databaseId
      )
      draft.syncStatus = "notSynced"
      setSyncStatus(draft.syncStatus)
    })

    storeUser(newUser)
    setUser(newUser)
  }

  const addFavoriteWish = wish => {
    const newUser = produce(user, draft => {
      draft.wishes = [wish, ...(user.wishes ?? [])]
      draft.syncStatus = "notSynced"
      setSyncStatus(draft.syncStatus)

      return draft
    })

    storeUser(newUser)
    setUser(newUser)
  }

  const removeFavoriteWish = (wish, updatedUser = null) => {
    const newUser = produce(updatedUser ?? user, draft => {
      draft.wishes =
        user.wishes?.filter(
          favorite => favorite.databaseId !== wish.databaseId
        ) ?? []
      draft.syncStatus = "notSynced"
      setSyncStatus(draft.syncStatus)
    })

    storeUser(newUser)
    setUser(newUser)
  }

  const setTeams = (teams, updatedUser = null) => {
    const newUser = produce(updatedUser ?? user, draft => {
      draft.teams = teams
      draft.syncStatus = "notSynced"
      setSyncStatus(draft.syncStatus)

      return draft
    })

    storeUser(newUser)
    setUser(newUser)
  }

  const setTeamInvites = invites => {
    const newUser = produce(user, draft => {
      //draft.teamInvites = invites
      draft.teamInviteIds = invites
      draft.syncStatus = "synced"
      //draft.syncStatus = 'notSynced'

      return draft
    })

    storeUser(newUser)
    setUser(newUser)
    setSyncStatus(newUser.syncStatus)
  }

  const removeTeamInvite = removableInvite => {
    return new Promise(async (resolve, reject) => {
      const newUser = produce(user, draft => {
        draft.teamInviteIds = user.teamInviteIds.filter(
          invite => invite.team[0].slug !== removableInvite.slug
        )
        draft.syncStatus = "notSynced"
      })

      storeUser(newUser)
      setUser(newUser)
      setSyncStatus(newUser.syncStatus)

      resolve(newUser)
    })
  }

  const addTeamInvite = invite => {
    const newUser = produce(user, draft => {
      draft.teamInviteIds = [invite, ...user.teamInviteIds]
      draft.syncStatus = "notSynced"
      setSyncStatus(draft.syncStatus)

      return draft
    })

    storeUser(newUser)
    setUser(newUser)
  }

  const logout = callback => {
    if (signedIn) {
      storeNotifications([])
      const newUser = normalizeAuthData(null)
      storeUser(newUser)
      setUser(newUser)
      setSignedIn(false)
      client.resetStore().then(r => callback())
    }
  }

  function userPointCalculator(user, nodes) {
    let points = 0
    const myDoneIds = user?.finishedTasks?.map(mission => mission.databaseId)
    const myDoneMissionsFiltered = nodes.filter(mission => {
      return myDoneIds?.includes(mission.databaseId)
    })

    myDoneMissionsFiltered.forEach(
      mission => (points += mission.SdgMeta.points)
    )

    return points
  }

  useEffect(() => {
    if (signedIn && "notSynced" === syncStatus) {
      const syncPlayer = () => {
        const teamInviteIds = user.teamInviteIds.map(invite => ({
          inviter: invite.inviter.databaseId,
          team: invite.team.map(t => t.databaseId),
        }))

        const wishesIds = user.wishes?.map(wish => wish.databaseId) ?? []

        updatePlayerMutation({
          variables: {
            code: user.code,
            finishedTaskIds: user.finishedTasks.map(
              mission => mission.databaseId
            ),
            tuduIds: user.tudus.map(mission => mission.databaseId),
            wishesIds,
            teamInviteIds,
            clientMutationId: uuidv4(),
          },
        }).then(_ => {
          setSyncStatus("synced")
        })
      }

      if (isOnline) {
        syncPlayer()
      } else {
        console.info("Delayed sync because user is offline")
      }
    }
  }, [isOnline, signedIn, syncStatus, user, updatePlayerMutation])

  const sharedStuff = {
    points,
    user,
    signedIn,
    syncStatus,
    logout,
    setTeams,
    setTeamInvites,
    addTeamInvite,
    removeTeamInvite,
    setRawUser,
    addDoneMission,
    removeDoneMission,
    addFavorite,
    removeFavorite,
    addFavoriteWish,
    removeFavoriteWish,
  }

  return (
    <UserContext.Provider value={sharedStuff}>{children}</UserContext.Provider>
  )
}

const useUser = () => useContext(UserContext)

export default useUser
