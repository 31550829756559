import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { ToastProvider } from 'react-toast-notifications'
import { NetworkProvider } from './src/hooks/useNetwork'
import { client } from './src/services/apollo'
import { UserProvider } from './src/hooks/useUser'
import { TeamInviteNotificationProvider } from './src/hooks/useTeamInviteNotifier'
//import { SERVICE_WORKER_UPDATE_FOUND } from './src/components/navLink'

export const wrapRootElement = ({ element }) => {

  return (
    <NetworkProvider>
      <ApolloProvider client={client}>
        <UserProvider>
          <ToastProvider autoDismiss={true}>
            <TeamInviteNotificationProvider>
                {element}
            </TeamInviteNotificationProvider>
          </ToastProvider>
        </UserProvider>
      </ApolloProvider>
    </NetworkProvider>
  )
}

/**
 * Set global variable to true when service worker has found an
 * update. This is used to trigger a page reload on route change.
 */
// export function onServiceWorkerUpdateReady () {
//   window[SERVICE_WORKER_UPDATE_FOUND] = true
// }
