import { graphql, useStaticQuery } from 'gatsby'

const gameConfig = graphql`
    query gameConfig {
        wp {
            texts {
                others {
                  wirkelwuensche
                  teamjoin
                  invitefriend
                  teamjoinpublic
                  backtoteamoverview
                  teamtotalscore
                  publicgroupconfirmation
                  groupvisibility
                  sharemission
                  news
                }
                notificationTexts {
                    codeCopied
                    codeGenerated
                    downloadCodeAndLogin
                    fieldGroupName
                    generateCode
                    mustBeOnlineToGenerateCode
                    pdfText
                    youMustBeOnline
                    areYouSureToLogOut
                    browserNotSupported
                    codeInvalid
                    finishRegistration
                    friendInvited
                    groupAdded
                    inviteDeclined
                    playWithCode
                    youJoinedGroup
                    youLeftGroup
                    yourCode
                    externalLinkConfirmation
                    addGroup
                    addNewGroup
                    close
                    groupOverview
                    hasntFinishedAnyTasks
                    instructions
                    inviteFriend
                    leaveGroup
                    myOverview
                    nameOfGroup
                    saveAsPdf
                    sdgInformationButton
                    seeScore
                    startGame
                    totalPoints
                    tudus
                    favoriteWishes
                    whatIsIt
                    youAreInvitedToTheGroup
                    youHaveNoNotifications
                    yourGroups
                    yourNotifications
                }
            }
            generalSettings {
                title
                description
            }
            gameConfig {
                welcomePage {
                    areYouNew
                    areYouRegistered
                    codeGenerierenLightbox
                    cookiePopup
                    cookiePopupAgreeButton
                    cookiePopupTitle
                    fieldGroupName
                    recaptcha
                    toRegistration
                    zugangscodeGenerierenModal
                    registeredTipList {
                        icon
                        text
                    }
                    chooseNick
                    registration
                }
                signedInConfig {
                    sdgTaskFinishedCongratulationSingular
                    sdgTaskFinishedCongratulationPlural
                    emptyTeamsList
                    emptyTudusList
                    sdgTaskFinishedCongratulationTitle
                    shareText
                }
            }
            menuConfig {
                hamburgerMenu {
                    menuItemsPublic {
                        menuItemUrl
                        menuItemLabel
                        menuItemIcon
                        menuItemHideFromSignedin
                    }
                    menuItemsSignedIn {
                        menuItemIcon
                        menuItemLabel
                        menuItemUrl
                    }
                    menuItemsSocial {
                        menuItemIcon
                        menuItemUrl
                    }
                    menuSdgInfoButtonText
                    linkToSdgs
                    menuBottomText
                    logoUrl
                    logo {
                        localFile {
                            publicURL
                        }
                    }
                    emailAddress
                    addToDesktop
                }
            }
        }
    }
`

function useGameConfig () {
  const staticData = useStaticQuery(gameConfig)

  return staticData.wp
}

export default useGameConfig
