import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import fetch from 'node-fetch'

const cache = new InMemoryCache()

/**
 * Handle errors.
 */
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  if (networkError) console.error(`[Network error]: ${networkError}`)
})

/**
 * Handle HTTP requests.
 */
const httpLink = createHttpLink({
  uri: process.env.API_URL,
  fetch,
  httpLinkOptions: {
    credentials: 'include',
  },
})

export const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    httpLink,
  ]),
  cache,
})
