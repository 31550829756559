import { useRef } from "react"
import { useToasts } from "react-toast-notifications"

/**
 * Renders one toast notification at a time.
 */
function useSingleToast(showOne = true) {
  const toastIdRef = useRef(undefined)
  const { addToast, removeToast } = useToasts()

  function addSingleToast(content, options) {
    if(showOne) removeToast(toastIdRef.current)
    addToast(content, options, toastId => (toastIdRef.current = toastId))
  }

  function removeThisToast(){
    removeToast(toastIdRef.current)
  }

  return { addSingleToast, removeThisToast }
}

export default useSingleToast
